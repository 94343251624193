.nav-primary {
  background-color: $black;

  .home & {
    background: none;
  }

  .wrap {
    @include padding(null 5%);

    @include breakpoint(md) {
      @include padding(null 2.1625%);
    }
  }

  .mobile-menu-btn {
    @include breakpoint(md) {
      display: none;
    }

    .mobile-menu-toggle {
      @extend %body;
      background: none;
      color: $white;
      cursor: pointer;
      font-size: 1.6rem;
      font-weight: 700;
      padding: 15px 0;
    }
  }

  .primary-menu-navigation {
    @include margin(0 null);
    @include padding(null null 10px 0);
    display: none;

    @include breakpoint(md) {
      @include flex(flex-start);
      padding-bottom: 0;
    }

    .menu-item {
      cursor: pointer;
      font-size: 1.4rem;
      font-weight: 700;
      letter-spacing: 1px;
      list-style-type: none;
      margin-bottom: 0;

      @include breakpoint(md) {
        margin-right: 40px;
        position: relative;
      }

      .fa-angle-down {
        @include breakpoint(md) {
          display: none;
        }
      }

      a {
        color: $white;
        display: block;
        padding: 10px 0;
      }

      &:hover .sub-menu {
        @include breakpoint(md) {
          display: block;
        }
      }

      .sub-menu {
        @include margin(0 null);
        background-color: $light;
        border: 1px solid $white;
        display: none;
        padding-left: 0;

        @include breakpoint(md) {
          @include position(absolute, 41px null null -15px);
          // width: 275px;
          width: 220px;
          z-index: 50;
        }

        .menu-item {
          @include padding(null 15px);
          border-bottom: 1px solid $white;

          @include breakpoint(md) {
            margin-right: 0;
          }

          &:last-child {
            border-bottom: none;
          }

          a {
            color: $black;
            display: block;

            @include breakpoint(md) {
              padding: 5px 0;
            }
          }
        }

        .home & {
          background: none;
          border: none;
          border-top: 1px solid $white;

          .menu-item {
            a {
              color: $white;
            }
          }
        }
      }
    }
  }
}
