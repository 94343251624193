$twelve:  8.333%;
$sixteen: 6.25%;

//* TWELVE COLUMNS
.col12-one {
  width: 100%;

  @include breakpoint(md) {
    width: calc(#{$twelve} * 1);
  }
}

.col12-two {
  width: 100%;

  @include breakpoint(md) {
    width: calc(#{$twelve} * 2);
  }
}

.col12-three {
  width: 100%;

  @include breakpoint(md) {
    width: calc(#{$twelve} * 3);
  }
}

.col12-four {
  width: 100%;

  @include breakpoint(md) {
    width: calc(#{$twelve} * 4);
  }
}

.col12-five {
  width: 100%;

  @include breakpoint(md) {
    width: calc(#{$twelve} * 5);
  }
}

.col12-six {
  width: 100%;

  @include breakpoint(md) {
    width: calc(#{$twelve} * 6);
  }
}

.col12-seven {
  width: 100%;

  @include breakpoint(md) {
    width: calc(#{$twelve} * 7);
  }
}

.col12-eight {
  width: 100%;

  @include breakpoint(md) {
    width: calc(#{$twelve} * 8);
  }
}

.col12-nine {
  width: 100%;

  @include breakpoint(md) {
    width: calc(#{$twelve} * 9);
  }
}

.col12-ten {
  width: 100%;

  @include breakpoint(md) {
    width: calc(#{$twelve} * 10);
  }
}

.col12-eleven {
  width: 100%;

  @include breakpoint(md) {
    width: calc(#{$twelve} * 11);
  }
}

.col12-twelve {
  width: 100%;
}

//* SIXTEEN COLUMNS
.col16-one {
  width: 100%;

  @include breakpoint(md) {
    width: calc(#{$sixteen} * 1);
  }
}

.col16-two {
  width: 100%;

  @include breakpoint(md) {
    width: calc(#{$sixteen} * 2);
  }
}

.col16-three {
  width: 100%;

  @include breakpoint(md) {
    width: calc(#{$sixteen} * 3);
  }
}

.col16-four {
  width: 100%;

  @include breakpoint(md) {
    width: calc(#{$sixteen} * 4);
  }
}

.col16-five {
  width: 100%;

  @include breakpoint(md) {
    width: calc(#{$sixteen} * 5);
  }
}

.col16-six {
  width: 100%;

  @include breakpoint(md) {
    width: calc(#{$sixteen} * 6);
  }
}

.col16-seven {
  width: 100%;

  @include breakpoint(md) {
    width: calc(#{$sixteen} * 7);
  }
}

.col16-eight {
  width: 100%;

  @include breakpoint(md) {
    width: calc(#{$sixteen} * 8);
  }
}

.col16-nine {
  width: 100%;

  @include breakpoint(md) {
    width: calc(#{$sixteen} * 9);
  }
}

.col16-ten {
  width: 100%;

  @include breakpoint(md) {
    width: calc(#{$sixteen} * 10);
  }
}

.col16-eleven {
  width: 100%;

  @include breakpoint(md) {
    width: calc(#{$sixteen} * 11);
  }
}

.col16-twelve {
  width: 100%;

  @include breakpoint(md) {
    width: calc(#{$sixteen} * 12);
  }
}

.col16-thirteen {
  width: 100%;

  @include breakpoint(md) {
    width: calc(#{$sixteen} * 13);
  }
}

.col16-fourteen {
  width: 100%;

  @include breakpoint(md) {
    width: calc(#{$sixteen} * 14);
  }
}

.col16-fifteen {
  width: 100%;

  @include breakpoint(md) {
    width: calc(#{$sixteen} * 15);
  }
}

.col16-sixteen {
  width: 100%;
}
