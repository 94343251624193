input[type='text'] {}

input[type='submit'] {
  cursor: pointer;

  .login &,
  .admin & {
    background-color: $black;
    color: $white;
    padding: 10px 15px;
  }
}

label {
  display: block;
  font-weight: 700;
  margin-bottom: 10px;
}

select {}

textarea {}

.form-group {
  margin-bottom: 30px;

  &.row {
    @include breakpoint(xs) {
      @include flex(flex-start);
    }
  }
}

.form-check-group {
  &.row {
    @include flex(flex-start);

    .form-check {
      margin-right: 30px;
    }
  }
}

.form-check {
  @include flex(flex-start);
  // &.form-check-inline {}

  .form-check-label {
    margin-bottom: 0;
  }

  .form-check-input {
    @include margin(null 5px 0 null);
    // margin-right: 5px;
  }
}

.form-control {
  border: 1px solid $black;
  padding: 5px 15px;

  .login &,
  .admin & {
    @include padding(10px null);
  }
}

.form-control-file {
  background-color: $light;
  border: 1px solid $black;
  padding: 10px 15px;
}
