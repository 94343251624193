.home {
  background-attachment: fixed;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  
  .site-footer {
    display: none;

    @include breakpoint(md) {
      @include position(fixed, null 0 30px);
      display: block;
      margin: 0 auto;
      max-width: 1366px;
      width: 100%;
    }

    .wrap {
      @include padding(null 5%);

      @include breakpoint(md) {
        @include padding(null 2.1625%);
      }
    }

    .footer-widgets-area {
      .widget {
        &.social-media-icons {
          ul {
            @include margin(0 null);
            @include flex(flex-end);
            padding-left: 0;

            li {
              @include margin(null 10px 0 null);
              font-size: 1.8rem;
              list-style-type: none;
              &:last-child {
                margin-right: 0;
              }

              a {
                color: $blue;

                &:hover {
                  color: $blue;
                }
              }
            }
          }
        }
      }
    }
  }
}
