.site-header {
  background-color: $black;
  // padding-top: 10px;
  padding-top: 30px;

  /*
  @include breakpoint(xs) {
    padding-top: 30px;
  }
  */

  .home & {
    background: none;
  }

  .wrap {
    @include padding(null 5%);

    @include breakpoint(md) {
      @include padding(null 2.1625%);
    }
  }

  .site-header-wrapper {
    border-bottom: 1px solid $white;
    padding-bottom: 10px;

    @include breakpoint(md) {
      @include flex(space-between);
    }

    &.no-border {
      @include flex(space-between);
      align-items: center;
      border-bottom: none;
      padding-bottom: 25px;

      .title-area {
        width: 80%;

        @include breakpoint(md) {
          width: auto;
        }
      }

      .header-widget-area {
        display: block;

        @include breakpoint(md) {
          @include flex(flex-end);
          align-items: center;
        }

        .widget {
          &.search {
            display: none;

            @include breakpoint(md) {
              display: block;
            }
          }
        }
      }
    }

    .title-area {}

    .header-widget-area {
      display: none;

      @include breakpoint(md) {
        @include flex(flex-end);
        align-items: center;
      }

      .home & {
        @include breakpoint(md) {
          display: none;
        }
      }

      .widget {
        &.search {
          height: 35px;
          margin-right: 30px;

          @include breakpoint(lg) {
            margin-right: 60px;
          }

          form {
            height: 100%;
          }

          input[type='text'],
          input[type='submit'] {
            background-color: $black;
            border: 1px solid $light;
            color: $light;
            font-size: 1.4rem;
            height: 100%;
          }

          input[type='text'] {
            margin-right: 15px;
            width: 185px;
          }

          input[type='submit'] {
            @include padding(null 10px);
            cursor: pointer;
            font-weight: 700;
          }
        }

        &.social-media-icons {
          ul {
            @include margin(0 null);
            @include flex(flex-end);
            padding-left: 0;

            li {
              @include margin(null 10px 0 null);
              list-style-type: none;
              &:last-child {
                margin-right: 0;
              }

              a {
                color: $light;

                &:hover {
                  color: $white;
                }
              }
            }
          }
        }

        &.landing-page-button {
          a {
            color: $light;

            &:hover {
              color: $white;
            }
          }
        }
      }
    }
  }
}
