h1,
h2,
h3,
h4,
h5,
h6 {
  @extend %body;
  @include margin(0 null);
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 150%;
}

p {
  @extend %body;
  font-size: 1.6rem;
  line-height: 150%;
  margin-top: 0;

  a {
    color: $link;
    font-weight: 700;
    text-decoration: none;
    transition: all .2s ease;
    &:hover {
      color: $black;
    }
  }
}

li {
  @extend p;
  margin-bottom: 10px;
}
