.masonry-sizer {
  width: 100%;

  @include breakpoint(xs) {
    width: 47.5%;
  }

  @include breakpoint(md) {
    width: 22.75%;
  }
}

.masonry-spacer {
  @include breakpoint(xs) {
    width: 5%;
  }

  @include breakpoint(md) {
    width: 3%;
  }
}

.masonry-grid {
  @include margin(0 null);
  padding-left: 0;

  .masonry-item {
    @extend .masonry-sizer;
    list-style-type: none;

    .masonry-item-caption {
      font-size: 1.4rem;

      p {
        font-size: 1.4rem;
      }

      .figure-num {
        float: left;
        font-size: 1.4rem;
        height: 16px;
        margin-top: -1px;
        padding-right: 10px;
        width: auto;
      }
    }
  }
}
