.page-template-podcast-page,
.single-podcast {
  .podcast-header {
    background-color: #555;
    color: $white;
    text-align: center;

    @include breakpoint(md) {
      text-align: left;
    }

    .col16-five {
      @include breakpoint(md) {
        padding-right: 40px;
        text-align: right;
      }
    }

    .custom-logo {
      display: inline-block;
      margin-bottom: 15px;

      @include breakpoint(md) {
        margin-bottom: 0;
      }
    }
  }
}

.page-template-podcast-page {
  .entry-podcast {
    margin-bottom: 60px;

    @include breakpoint(md) {
      @include flex(space-between);
    }

    h2 {
      font-size: 2rem;

      @include breakpoint(md) {
        text-align: right;
      }
    }
  }
}
