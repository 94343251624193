.pagination {
  @include flex(space-between);
  align-items: center;
  width: 100%;

  .pagination-previous {
    font-size: 1.2rem;
    text-align: left;
    width: 25%;
  }

  .pagination-next {
    font-size: 1.2rem;
    text-align: right;
    width: 25%;
  }

  .pagination-pages {
    font-size: 1.2rem;
    text-align: center;
    width: 50%;

    p {
      font-size: 1.2rem;
    }
  }

  &.exhibition-pagination {
    margin-top: 60px;

    @include breakpoint(md) {
      @include position(fixed, null 0 30px);
      margin: 0 auto;
      width: 95%;
    }

    .pagination-previous,
    .pagination-next,
    .pagination-pages {
      font-size: 1.6rem;

      img {
        display: inline-block;
      }
    }
  }
}
