//* VIDEO IFRAMES
.video-container {
  height: 0;
  position: relative;
  padding-bottom: 56.25%;

  iframe {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

//* CONTENT IFRAMES
.content-container {
  // height: 0;
  height: 100vh;
  position: relative;
  // padding-bottom: 56.25%;

  iframe {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}
