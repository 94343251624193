.image-grid {
  @include margin(0 null);
  padding-left: 0;

  @include breakpoint(xs) {
    @include flex(flex-start);
  }

  .image-grid-item {
    list-style-type: none;
    margin-bottom: 30px;

    @include breakpoint(xs) {
      margin-right: 5%;
      width: 47.5%;
    }

    @include breakpoint(md) {
      margin-right: 3%;
      width: 22.75%;
    }

    &:nth-child(even) {
      @include breakpoint(xs) {
        margin-right: 0;
      }

      @include breakpoint(md) {
        margin-right: 3%;
      }
    }

    &:nth-child(4n) {
      @include breakpoint(md) {
        margin-right: 0;
      }
    }

    a {
      color: $black;
    }

    .image-grid-image-thumbnail {
      width: 100%;
    }

    .image-grid-image-bkgd {
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;
      min-height: 250px;
      width: 100%;
    }

    .image-grid-header {
      background-color: $gray;
      // padding: 4px;

      &.image-grid-header-transparent {
        background: none;
      }

      .image-grid-title {
        font-size: 1.4rem;
        font-weight: 400 !important;
        padding: 4px;

        &.image-grid-title-box {
          background-color: $gray;
          float: right;
          // padding: 4px;
          text-align: right;
        }
      }
    }
  }

  //* FIVE ITEMS PER ROW
  &.image-grid-five-items {
    .image-grid-item {
      @include breakpoint(xl) {
        width: 17.5%;
      }

      &:nth-child(4n) {
        @include breakpoint(xl) {
          margin-right: 3%;
        }
      }

      &:nth-child(5n) {
        @include breakpoint(xl) {
          margin-right: 0;
        }
      }
    }
  }
}
