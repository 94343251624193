.single-article {
  h1 {
    margin-bottom: 28px;
  }

  .leftside-content {
    position: relative;

    .content-wrapper {
      padding-right: 0;
      position: fixed;
      width: inherit;
    }
  }

  .rightside-content {
    @extend .leftside-content;

    .content-wrapper {
      position: static;

      @include breakpoint(md) {
        position: fixed;
      }
    }
  }

  .images-nav-btn {
    padding-right: 40px;
    text-align: right;

    span {
      display: inline-block;
    }
  }

  #images-pane {
    height: 900px;
    overflow: hidden;
    padding-right: 40px;
    position: relative;

    .images-list {
      @include margin(0 null);
      padding-left: 0;

      .image-item {
        list-style-type: none;
        height: 900px;

        .image-caption {
          font-size: 1.4rem;
          margin-top: 10px;

          .image-caption-num {
            @include margin(-1px 10px null null);
            float: left;
            height: 14px;
          }

          p {
            font-size: 1.4rem;
          }
        }
      }
    }
  }

  .entry-footer {
    margin-top: 60px;

    .footnote {
      font-size: 1.2rem;
      margin-bottom: 30px;

      p {
        font-size: 1.2rem;
      }

      .footnote-num {
        float: left;
        width: 35px;
      }
    }
  }

  #footnote-pane {
    height: 900px;
    overflow: hidden;
    position: relative;

    .footnote-list {
      @include margin(0 null);
      padding-left: 0;

      .footnote-item {
        font-size: 1.2rem;
        height: 900px;
        list-style-type: none;
        margin-bottom: 30px;

        p {
          font-size: 1.2rem;
        }

        .footnote-num {
          float: left;
          // font-size: 1.2rem;
          // margin-right: 10px;
          width: 35px;
        }
      }
    }
  }
}
