//* Colors
$black:   #000;
$blue:    #2372ab;
$gray:    #ccc;
$white:   #fff;
$light:   #eee;
$link:    #8b8a8c;

//* Fonts
$body:    'vera-sans-n4', 'vera-sans', Verdana, Arial, Helvetica, sans-serif;

//* Mixins
@mixin flex($justify) {
  display: flex;
  flex-wrap: wrap;
  justify-content: $justify;
}

//* Placeholders
%body {
  font-family: $body;
  font-weight: 400;
}
