.admin {
  h1 {
    margin-bottom: 30px;
  }

  .admin-list {
    @include margin(0 null);
    padding-left: 0;

    li {
      list-style-type: none;
      margin-bottom: 30px;

      ul {
        li {
          margin-bottom: 10px;
        }
      }
    }
  }
}
