.single-post {
  .slick-horizontal {
    margin-bottom: 30px;
  }

  .slick-navigation {
    margin: 0 auto;
    position: relative;
    width: 90%;

    .slide {
      cursor: pointer;

      img {
        border: 1px solid $link;
        margin: 0 auto;
        transition: all .2s ease;
        &:hover {
          border-color: $black;
        }
      }
    }

    .nav-btn {
      @include position(absolute, 50% null null);
      transform: translateY(-50%);
      z-index: 50;

      &.slick-prev {
        left: -25px;
      }

      &.slick-next {
        right: -25px;
      }
    }
  }

  .slick-gallery {
    margin: 0 auto;
    position: relative;
    width: 90%;

    @include breakpoint(md) {
      width: 100%;
    }

    .slide {
      margin: 0;

      @include breakpoint(xxs) {
        @include margin(null 15px);
      }

      .thumbnail {
        height: 150px;
        margin-bottom: 15px;
        overflow: hidden;
        position: relative;

        @include breakpoint(xl) {
          height: 200px;
        }

        img {
          @include position(absolute, 50% null null 50%);
          max-width: 300%;
          transform: translate(-50%, -50%);
          width: 138%;

          @include breakpoint(xxs) {
            width: 215%;
          }
        }
      }

      figcaption {
        font-size: 1.2rem;

        p {
          font-size: 1.2rem;
        }
      }
    }

    .nav-btn {
      @include position(absolute, 65px null null);
      cursor: pointer;
      // transform: translateY(-50%);
      z-index: 50;

      @include breakpoint(xl) {
        top: 88px;
      }

      &.slick-prev {
        left: -25px;

        @include breakpoint(md) {
          left: -10px;
        }
      }

      &.slick-next {
        right: -25px;

        @include breakpoint(md) {
          right: -10px;
        }
      }
    }
  }
}
