@mixin breakpoint( $break ) {
  @if($break == xxs) {
    @media(min-width:400px) {
      @content;
    }
  }

  @else if($break == xs) {
    @media(min-width:550px) {
      @content;
    }
  }

  @else if($break == sm) {
    @media(min-width:768px) {
      @content;
    }
  }

  @else if($break == md) {
    @media(min-width:992px) {
      @content;
    }
  }

  @else if($break == lg) {
    @media(min-width:1200px) {
      @content;
    }
  }

  @else if($break == xl) {
    @media(min-width:1325px) {
      @content;
    }
  }

  @else if($break == xxl) {
    @media(min-width:1650px) {
      @content;
    }
  }
}
