.table {
  border: 1px solid $black;
  border-spacing: 0;
  width: 100%;

  thead {
    background-color: $light;
    display: none;
    text-align: left;

    @include breakpoint(md) {
      display: table-header-group;
    }

    tr {
      display: block;

      @include breakpoint(md) {
        display: table-row;
      }

      th {
        display: block;
        padding: 0;

        @include breakpoint(md) {
          display: table-cell;
          padding: 2px;
        }
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid $black;
      display: block;
      padding: 10px;

      @include breakpoint(md) {
        display: table-row;
      }

      &:nth-child(even) {
        background-color: $light;
      }

      &:last-child {
        border-bottom: none;
      }

      td {
        display: block;
        font-size: 1.4rem;
        padding: 5px 0;

        @include breakpoint(md) {
          @include padding(10px 2px);
          display: table-cell;
        }
      }
    }
  }
}
